import { useState } from 'react';
import { Button, Space, Table, Tag } from 'antd';
import { PccCensusDto, PccPatientDataDto } from '@medone/medonehp-api-client';
import moment from 'moment';

import { setCurrentPccCensus, selectPccLoading } from '../../slice';
import { formatDateTime, formatPccGender } from '../../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { fetchPccPatient } from '../../../census/slice.patients';
import { censusSlice } from '../../../census/slice';

const { Column } = Table;

type Props = {
    census: PccCensusDto[];
};

const PatientTable = ({ census }: Props) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector<boolean>(selectPccLoading);
    const [processing, setProcessing] = useState<boolean>(false);

    const handleDetailsClick = async (item: PccCensusDto) => {
        setProcessing(true);

        if (item.patientIntakeId != null) {
            await dispatch(fetchPccPatient(item.patientIntakeId));
        } else {
            // Force details modal to show for non-linked patients
            dispatch(
                censusSlice.actions.setPccPatient(
                    PccPatientDataDto.fromJS({
                        pccCensus: item,
                    })
                )
            );
        }

        await dispatch(setCurrentPccCensus(item, true));

        setProcessing(false);
    };

    const renderViewDetails = (item: PccCensusDto) => {
        return (
            <Space size={0}>
                {item.isPatientPccLinked && <Tag color="green">Linked</Tag>}

                <Button key={item.id} type="link" onClick={() => handleDetailsClick(item)} loading={processing}>
                    View Details
                </Button>
            </Space>
        );
    };

    return (
        <Table size="large" rowKey={(x) => x.patientId} pagination={{ defaultPageSize: 10 }} dataSource={census} className="census-table" loading={loading}>
            <Column<PccCensusDto>
                title="Patient Name"
                key="lastName"
                render={(item: PccCensusDto) => (
                    <>
                        {item.lastName}, {item.firstName}
                    </>
                )}
            />
            <Column<PccCensusDto> title="MRN" dataIndex="medicalRecordNumber" key="medicalRecordNumber" />
            <Column<PccCensusDto> title="Gender" dataIndex="gender" key="gender" render={(gender) => formatPccGender(gender)} />
            <Column<PccCensusDto> title="Admission Date" dataIndex="admissionDate" key="admissionDate" render={(date: moment.Moment) => <>{formatDateTime(date, '', 'L')}</>} />
            <Column<PccCensusDto> title="Date Of Birth" dataIndex="birthDate" key="birthDate" render={(date: moment.Moment) => <>{formatDateTime(date)}</>} />
            <Column<PccCensusDto>
                title="Room Number"
                key="roomDesc"
                render={(item: PccCensusDto) => item.unitDesc && <>{`${item.unitDesc}${item.floorDesc} ${item.roomDesc}-${item.bedDesc}`}</>}
            />
            <Column<PccCensusDto> title="Status" dataIndex="patientStatus" key="patientStatus" />

            <Column<PccCensusDto> title="" key="details" className="actions" render={(item) => renderViewDetails(item)} />
        </Table>
    );
};

export default PatientTable;
