import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { CensusDto, StagedNoteDto, VisitDto } from '@medone/medonehp-api-client';
import classNames from 'classnames';

import { getOrCreateNote } from '../slice.notes';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { selectCensusItemVisit } from '../slice';
import { RootState } from '../../../../../shared/store';
import { fetchStagedNotesForIntake, promoteStagedNote } from '../slice.staged-notes';
import { AuthState } from '../../../../../shared/common/auth/models';
import { selectAuth } from '../../../../../shared/common/auth/slice';
import { isMobileView } from '../../../../../shared/common/helpers';
import { PostAcutePaths } from '../../index.routes';

import StagedNoteModal from './staged-note-modal';

type Props = {
    census: CensusDto;
    setLoading: (loading: boolean) => void;
};

const OpenNoteButton = ({ census, setLoading }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const auth = useAppSelector<AuthState>(selectAuth);
    const visit = useAppSelector<VisitDto>((state: RootState) => selectCensusItemVisit(state, census));
    const [isStagedNoteVisible, setStagedNoteVisible] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const currentStagedNote = useRef<StagedNoteDto>();
    const openNoteBtnClasses = classNames({
        'ml-2': true,
        'mr-3': !isMobileView(),
        'open-note-mobile': isMobileView(),
    });

    const handleOpen = async () => {
        setIsProcessing(true);
        setLoading(true);

        const { localAccountId } = auth.account;
        const stagedNotes = await dispatch(fetchStagedNotesForIntake(visit.patientIntakeId));
        const stagedNote = stagedNotes.find((x) => x.noteType === visit.noteType);

        if (stagedNote != null) {
            currentStagedNote.current = stagedNote;

            // If the current user created the staged note, take note and don't prompt
            if (stagedNote.userId === localAccountId) {
                await handlePreppedNote();
            } else {
                // Store page coming from, so we can redirect them back after the note sign/close
                localStorage.setItem('staged-note-redirect-to', window.location.pathname);

                // Show staged note confirmation modal
                setStagedNoteVisible(true);
                setLoading(false);
            }
        } else {
            await handleNewNote();
        }

        setIsProcessing(false);
    };

    const handlePreppedNote = async () => {
        const result = await dispatch(promoteStagedNote(currentStagedNote.current.id, visit.id));

        if (result != null) {
            await handleNewNote();
        }

        setLoading(false);
        setStagedNoteVisible(false);
    };

    const handleNewNote = async () => {
        const result = await dispatch(getOrCreateNote(visit, census, true));
        if (result != null) {
            // Store page coming from, so we can redirect them back after the note sign/close
            localStorage.setItem('note-redirect-to', window.location.pathname);

            // Navigate to note edit URL
            history.push(`${PostAcutePaths.Notes.Edit}/${result.id}`);
        }

        setLoading(false);
    };

    return (
        <>
            <StagedNoteModal
                census={census}
                isVisible={isStagedNoteVisible}
                currentStagedNote={currentStagedNote.current}
                setVisible={setStagedNoteVisible}
                handlePreppedNote={handlePreppedNote}
                handleNewNote={handleNewNote}
            />

            <Button
                onClick={() => handleOpen()}
                type="primary"
                className={openNoteBtnClasses}
                // Prevent user from being able to request more than 1 note at a time
                disabled={isProcessing}
            >
                Open Note
            </Button>
        </>
    );
};

export default OpenNoteButton;
