import { useEffect, useState } from 'react';
import { Alert, Col, Row, Skeleton, Card } from 'antd';
import { FacilityDto, FacilityScheduleDto, ProviderSchedulesDto } from '@medone/medonehp-api-client';

import { getScheduleForProvider, selectSchedules } from '../slice.schedules';
import { selectFacilities } from '../../admin/slice.facilities';
import { AuthState } from '../../../../../shared/common/auth/models';
import { selectAuth } from '../../../../../shared/common/auth/slice';
import { useAppSelector, useAppDispatch } from '../../../../../shared/hooks';

import { isMobileView, isTabletView } from '../../../../../shared/common/helpers';

import ScheduleTable from './schedule-table';
import ScheduleCard from './schedule-card';
import DischargeModal from '../../census/components/notes/discharge-modal';

const Results = () => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<AuthState>(selectAuth);
    const schedules = useAppSelector<ProviderSchedulesDto[]>(selectSchedules);
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const [loading, setLoading] = useState<boolean>();
    const [userId, setUserId] = useState<string>();

    const renderMobileCards = (facility: FacilityScheduleDto) => {
        return (
            <div className="card-container">
                <h3>{facility.facilityName}</h3>

                {facility.schedules.map((schedule) => (
                    <ScheduleCard key={schedule.id} schedule={schedule} />
                ))}
            </div>
        );
    };

    const getSchedule = async () => {
        setLoading(true);

        await dispatch(getScheduleForProvider(userId));

        setLoading(false);
    };

    useEffect(() => {
        if (userId != null) {
            getSchedule();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userId]);

    useEffect(() => {
        if (auth?.account?.localAccountId != null && userId == null) {
            setUserId(auth?.account?.localAccountId);
        }
    }, [auth, userId]);

    return (
        <Row gutter={48} className="schedule content">
            <Col span={24}>
                <DischargeModal openDrawer={false} />

                <Skeleton active loading={loading}>
                    {facilities != null && schedules != null && schedules.length > 0 ? (
                        <div id="providers-schedule">
                            {schedules.map((scheduleGroup) => {
                                return (
                                    <Card key={scheduleGroup.regionId} className="mt-3" title={scheduleGroup.regionName}>
                                        {scheduleGroup.facilities.map((facility) =>
                                            isMobileView() || isTabletView() ? renderMobileCards(facility) : <ScheduleTable key={facility.facilityId} facilitySchedule={facility} />
                                        )}
                                    </Card>
                                );
                            })}
                        </div>
                    ) : (
                        <Alert message="No schedules found for the selected date" type="info" showIcon className="mt-2" />
                    )}
                </Skeleton>
            </Col>
        </Row>
    );
};

export default Results;
