import { useState } from 'react';
import { Col, Descriptions, Modal, Row, Spin, Table, Tag } from 'antd';
import { RegulatoryNoteDto, VisitDueDateRecord } from '@medone/medonehp-api-client';
import classNames from 'classnames';
import moment from 'moment';

import { selectRequiredVisitTypes, selectVisitTypes } from '../../../../../../shared/common/data/slice';
import { formatDateTime, getNoteTypeShortName } from '../../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { clearVisitDuesForIntake, fetchVisitDuesForIntake, visitDueSelectors } from '../../slice';

type Props = {
    admissionDate: moment.Moment;
    admittedToId: number;
    patientIntakeId: number;
    isMobile?: boolean;
};

const { Column } = Table;

const VisitDueBadge = ({ admissionDate, admittedToId, patientIntakeId, isMobile }: Props) => {
    const dispatch = useAppDispatch();
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const visitTypes = useAppSelector(selectVisitTypes);
    const providerVisitTypes = useAppSelector(selectRequiredVisitTypes);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const handleClickBadge = async () => {
        setLoading(true);

        await dispatch(fetchVisitDuesForIntake(admittedToId, patientIntakeId));

        setLoading(false);
        setShowDetails(true);
    };

    const handleClose = () => {
        dispatch(clearVisitDuesForIntake(patientIntakeId));

        setShowDetails(false);
    };

    const renderVisitType = (value) => {
        return visitTypes?.find((x) => x.id === value)?.name;
    };

    const renderProviderVisitType = (value) => {
        return providerVisitTypes?.find((x) => x.id === value)?.name;
    };

    const renderVisitDetails = (record: VisitDueDateRecord) => {
        return (
            record &&
            record.visit && (
                <Table bordered size="small" pagination={false} dataSource={[record.visit]}>
                    <Column<RegulatoryNoteDto> title="Visit Date" dataIndex="date" key="date" render={(data) => formatDateTime(data)} />
                    <Column<RegulatoryNoteDto> title="Note Type" dataIndex="noteType" key="noteType" render={(data) => getNoteTypeShortName(data)} />
                    <Column<RegulatoryNoteDto> title="Provider Name" dataIndex="signedByUserName" key="signedByUserName" />
                    <Column<RegulatoryNoteDto> title="Provider Type" dataIndex="providerType" key="providerType" render={(data) => renderProviderVisitType(data)} />
                </Table>
            )
        );
    };

    const renderDetails = () => {
        return (
            visitDue.dueDates && (
                <Modal
                    title="Regulatory Information"
                    open={showDetails}
                    width="80%"
                    destroyOnClose
                    onCancel={handleClose}
                    footer={null}
                    className="regulatory-details"
                    style={{ top: 45 }}
                >
                    <Descriptions bordered className="mb-2">
                        <Descriptions.Item label="Admission Date">{formatDateTime(admissionDate, '', 'L')}</Descriptions.Item>
                        <Descriptions.Item label="Next Visit Due In">{renderVisitDue(visitDue.overDueInDays, false)}</Descriptions.Item>
                        <Descriptions.Item label="Next Visit Due By">{formatDateTime(visitDue.nextVisitDueDate, 'N/A')}</Descriptions.Item>
                        <Descriptions.Item label="Visit Type">{renderVisitType(visitDue.visitType)}</Descriptions.Item>
                        <Descriptions.Item label="Provider Type">{renderProviderVisitType(visitDue.providerVisitType)}</Descriptions.Item>
                        <Descriptions.Item label="Is Vet Admin?">{visitDue.isVetAdmin ? 'Yes' : 'No'}</Descriptions.Item>
                        <Descriptions.Item label="How did we get the visit due?">{visitDue.reason}</Descriptions.Item>
                    </Descriptions>

                    {visitDue.dueDates && visitDue.dueDates.length > 0 && (
                        <div className="details-table">
                            <Table
                                title={() => (
                                    <Row gutter={20}>
                                        <Col span={14}>
                                            Visit Schedule
                                            <div>
                                                <small>The dates below represent the visits that "should" happen with a min/max date range and provider type for that visit.</small>
                                            </div>
                                        </Col>
                                        <Col span={10} className="text-right">
                                            <small className="table-legend">
                                                <div className="no-visit">No / Missed visit</div>
                                                <div className="has-visit">Valid Visit, expand for details</div>
                                                <div className="due-visit">Next Visit Due</div>
                                            </small>
                                        </Col>
                                    </Row>
                                )}
                                bordered
                                size="small"
                                rowKey={(x) => x.visitNumber}
                                pagination={false}
                                dataSource={visitDue.dueDates}
                                scroll={{ y: 'calc(100vh - 400px)' }}
                                expandable={{
                                    indentSize: 0,
                                    expandedRowRender: renderVisitDetails,
                                    rowExpandable: (record: VisitDueDateRecord) => record.visit != null,
                                }}
                                onRow={(record) => {
                                    const isNextDue = visitDue.nextVisitDueDate?.isSame(record.maxDate);
                                    const classes = classNames({
                                        'has-visit': record.visit != null,
                                        'no-visit': record.visit == null && !isNextDue,
                                        'due-visit': isNextDue,
                                    });

                                    return {
                                        className: classes,
                                    };
                                }}
                            >
                                <Column<VisitDueDateRecord> title="Visit #" dataIndex="visitNumber" key="visitNumber" />
                                <Column<VisitDueDateRecord> title="Visit Date" dataIndex="date" key="date" render={(data) => formatDateTime(data)} />
                                <Column<VisitDueDateRecord> title="Min Date" dataIndex="minDate" key="minDate" render={(data) => formatDateTime(data)} />
                                <Column<VisitDueDateRecord> title="Max Date" dataIndex="maxDate" key="maxDate" render={(data) => formatDateTime(data)} />
                                <Column<VisitDueDateRecord> title="Provider Type" dataIndex="requiredVisitType" key="visitType" render={(data) => renderProviderVisitType(data)} />
                                <Column<VisitDueDateRecord> title="Visit Type" dataIndex="visitType" key="visitType" render={(data) => renderVisitType(data)} />
                            </Table>
                        </div>
                    )}
                </Modal>
            )
        );
    };

    const renderVisitDue = (overDueInDays?: number, handleClick = true) => {
        let color;

        if (overDueInDays < 0) {
            color = '#f1c40f';
        } else if (overDueInDays <= 10 && overDueInDays >= 1) {
            color = '#e74c3c';
        } else {
            color = '#8b0d00';
        }

        let clickProps = null;
        const style = { display: overDueInDays ? 'flex' : '' };

        if (handleClick) {
            style['cursor'] = 'pointer';

            clickProps = {
                onClick: handleClickBadge,
            };
        }

        return (
            overDueInDays != null && (
                <Spin spinning={isLoading}>
                    <Tag color={color} className="visit-due" style={style} {...clickProps}>
                        {overDueInDays}
                    </Tag>
                </Spin>
            )
        );
    };

    return visitDue ? (
        <>
            {renderVisitDue(visitDue.overDueInDays, !isMobile)}
            {renderDetails()}
        </>
    ) : (
        <></>
    );
};

export default VisitDueBadge;
