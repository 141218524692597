import { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Button, Modal } from 'antd';
import { QuickNoteDto, PatientDto } from '@medone/medonehp-api-client';
import moment from 'moment';
import classNames from 'classnames';

import { submitQuickNote, selectIsQuickNoteVisible } from '../../slice.notes';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { handleError } from '../../../../../../shared/common/HandleErrors';
import { fetchPatient, selectPatient } from '../../slice.patients';
import { censusSlice } from '../../slice';

import QuickNoteForm from './quick-note-form';

type Props = {
    patientId: number;
    facilityId?: number;
    patientName: string;
    mobile?: boolean;
    fromCensus?: boolean;
    buttonSmall?: boolean;
    afterSubmit?: () => void;
    patientIntakeId: number;
};

const QuickNote = ({ patientId, facilityId, patientName, mobile, fromCensus, buttonSmall, afterSubmit, patientIntakeId }: Props) => {
    const dispatch = useAppDispatch();
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const isVisible = useAppSelector<number>(selectIsQuickNoteVisible);
    const [form] = Form.useForm();
    const patient = useAppSelector<PatientDto>(selectPatient);
    const buttonClasses = classNames({
        'btn-quick-note': true,
        'ml-1': !mobile,
        'btn-light-blue': fromCensus,
    });

    const handleOpen = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (!facilityId) {
            await dispatch(fetchPatient(patientId));
        }

        dispatch(censusSlice.actions.setIsQuickNoteVisible(patientId));
    };

    const handleSubmit = async () => {
        setConfirmLoading(true);

        try {
            const values = await form.validateFields();
            const patchedValues = {
                ...values,
                patientId,
                patientName,
                facilityId: facilityId ? facilityId : patient.facilityId,
                reminderDate: moment(values.reminderDate).startOf('day'),
            } as QuickNoteDto;

            const result = await dispatch(submitQuickNote(patchedValues));

            if (result) {
                toast.success(`Quick note submitted.`);

                form.resetFields();

                if (afterSubmit != null) {
                    afterSubmit();
                }
            }

            dispatch(censusSlice.actions.setIsQuickNoteVisible(null));
        } catch (errors) {
            handleError(errors, () => true);
        }

        setConfirmLoading(false);
    };

    return (
        <>
            <Button type="primary" size={buttonSmall ? 'small' : 'middle'} ghost={fromCensus} className={buttonClasses} onClick={handleOpen} block={mobile}>
                Quick Note
            </Button>

            <Modal
                title={`Create Quick Note for ${patientName}`}
                open={isVisible === patientId}
                onOk={handleSubmit}
                confirmLoading={confirmLoading}
                onCancel={() => dispatch(censusSlice.actions.setIsQuickNoteVisible(null))}
                footer={[
                    <Button key="Cancel" onClick={() => dispatch(censusSlice.actions.setIsQuickNoteVisible(null))}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
                className={mobile && 'mobile-modal'}
                style={{ top: 20 }}
                width={mobile ? '100%' : '80%'}
                destroyOnClose
            >
                <QuickNoteForm
                    form={form}
                    quickNote={QuickNoteDto.fromJS({ reminderDate: moment(), needsFollowUp: false, isDraft: false, specialties: [], patientId: patientId })}
                    patientIntakeId={patientIntakeId}
                />
            </Modal>
        </>
    );
};

export default QuickNote;
