import { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Tooltip, FormInstance, Row, Select } from 'antd';
import { QuickNoteDto, EnumSelectListDto, Specialties, PatientIntakeDto, PatientIntakeSpecialtyDto } from '@medone/medonehp-api-client';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { selectQuickNoteTypes, selectSpecialties } from '../../../../../../shared/common/data/slice';
import { filterOptionsStartsWith, getSelectListOptions, isInRole } from '../../../../../../shared/common/helpers';
import { fetchIntakeDto } from '../../slice.patient-intakes';
import { specialtiesSelectors } from '../../slice';

import CustomDatePicker from '../../../../../../shared/common/components/CustomDatePicker';
import CustomSelect, { getEnumCustomSelectListOptions } from '../../../../../../shared/common/components/CustomSelect';
import Referrals from '../referrals';
import { selectAuth } from '../../../../../../shared/common/auth/slice';
import { AuthState } from '../../../../../../shared/common/auth/models';
import { Role } from '../../../../../../shared/common/auth/RoleAuth';

const { TextArea } = Input;

type Props = {
    form: FormInstance;
    quickNote: QuickNoteDto;
    patientIntakeId: number;
};

const dateFormat = 'YYYY-MM-DD';

const QuickNoteForm = ({ form, quickNote, patientIntakeId }: Props) => {
    const dispatch = useAppDispatch();
    const quickNoteTypes = useAppSelector<EnumSelectListDto[]>(selectQuickNoteTypes);
    const renderDateLabel = () => <Tooltip title="Note will appear on this date">Date</Tooltip>;
    const allSpecialtyOptions = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const intakeSpecialties = useAppSelector((state) => specialtiesSelectors.selectById(state.census.specialties, patientIntakeId));
    const [intake, setIntake] = useState<PatientIntakeDto>(null);
    const [specialtyOptions, setSpecialtyOptions] = useState<EnumSelectListDto[]>(null);
    const [patientHasSpecialties, setPatientHasSpecialties] = useState<boolean>(false);
    const [isFollowup, setIsFollowup] = useState<boolean>(false);
    const auth = useAppSelector<AuthState>(selectAuth);
    const isInAcoQualityCommitteeRole = isInRole(auth.permissions, [Role.ACO_QUALITY_COMMITTEE])

    const disablePrimaryDeselection = (obj: any, arr: any[]) => {
        return patientHasSpecialties && obj.id === Specialties.Primary && arr.length > 1;
    };

    const referredCallback = async (dto: PatientIntakeSpecialtyDto) => {
        const value = form.getFieldValue('specialties');
        if (!value.includes(dto.specialty)) {
            toast.warn(`Making a referral now will require a ${dto.specialtyFormatted} provider to Acknowledge this Quick Note.`);
        }
    };

    const onFollowupChange = (e: CheckboxChangeEvent) => {
        setIsFollowup(e.target.checked);
        if (e.target.checked) {
            const value = form.getFieldValue('followupDate');
            if (!value) {
                form.setFieldValue('followupDate', moment().add(1, 'day'));
            }
        }
    };

    useEffect(() => {
        const initSpecialties = async () => {
            const intake = await dispatch(fetchIntakeDto(patientIntakeId));

            if (intake) {
                setIntake(intake);

                const now = moment();
                const patientSpecialties = intakeSpecialties.specialties?.filter((x) => x.startDate <= now && (x.endDate == null || x.endDate >= now)).map((x) => x.specialty);
                const patientHasSpecialties = !!patientSpecialties?.length;
                const patientSpecialtyOptions = allSpecialtyOptions.filter((x) => patientSpecialties.indexOf(x.id) >= 0);

                setPatientHasSpecialties(patientHasSpecialties);
                setSpecialtyOptions(patientHasSpecialties ? patientSpecialtyOptions : allSpecialtyOptions);

                if (patientSpecialties.length === 1) {
                    form.setFieldValue('specialties', [patientSpecialties[0]]);
                } else if (patientSpecialties.length > 1) {
                    const primary = patientSpecialties.find((x) => x === Specialties.Primary);

                    if (primary != null) {
                        form.setFieldValue('specialties', [primary]);
                    }
                }
            }
        };

        if (allSpecialtyOptions && patientIntakeId) {
            initSpecialties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, patientIntakeId, allSpecialtyOptions, intakeSpecialties]);

    useEffect(() => {
        if (quickNote?.needsFollowUp) {
            setIsFollowup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickNote]);

    return (
        <Form form={form} initialValues={quickNote} layout="vertical">
            <Form.Item label={renderDateLabel()} name="reminderDate" rules={[{ required: true, message: 'Note will appear on this date' }]}>
                <CustomDatePicker />
            </Form.Item>

            <Form.Item label="Type" name="quickNoteType" className="required" rules={[{ required: true, message: 'Required' }]}>
                <CustomSelect options={getEnumCustomSelectListOptions(quickNoteTypes)} />
            </Form.Item>

            <Row justify="space-between" align="middle" style={{ gap: "24px" }}>
                <Form.Item label="Specialties" name="specialties" rules={[{ required: true, message: 'Specialties is required.' }]} style={{ flexGrow: "1" }}>
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOptionsStartsWith}
                        options={getSelectListOptions(specialtyOptions, 'name', 'id', false, null, disablePrimaryDeselection)}
                    />
                </Form.Item>

                <Referrals patientIntake={intake} onReferralCreatedCallback={referredCallback} />
            </Row>

            <Form.Item label="Body" name="value" className="required" rules={[{ required: true, message: 'Required' }]}>
                <TextArea autoSize={{ minRows: 12 }} />
            </Form.Item>

            <Row justify="end">
                <Form.Item name="needsFollowUp" valuePropName="checked" className="mb-0" labelAlign="right">
                    <Checkbox onChange={onFollowupChange}>Needs follow up</Checkbox>
                </Form.Item>

                <Form.Item name="followupDate" className="ml-1">
                    <CustomDatePicker disabled={!isFollowup} min={moment().format(dateFormat)} />
                </Form.Item>
            </Row>

            {!quickNote.id && (
                <Row justify="end">
                    <Form.Item name="isDraft" valuePropName="checked" className="mb-0" labelAlign="right">
                        <Checkbox>Mark as draft</Checkbox>
                    </Form.Item>
                </Row>
            )}

            {isInAcoQualityCommitteeRole && (
                <Row justify="end">
                    <Form.Item name="isSentFromAcoQualityCommittee" valuePropName="checked" className="mb-0" labelAlign="right">
                        <Checkbox>Send from ACO Quality Committee</Checkbox>
                    </Form.Item>
                </Row>
            )}
        </Form>
    );
};

export default QuickNoteForm;
