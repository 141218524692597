import { PayloadAction } from '@reduxjs/toolkit';
import {
    ProviderSchedulesDto,
    SchedulesClient,
    VisitDto,
    VisitFacilityTypeUpdateDto,
    ScheduledPatientIntakeStatsDto,
    CensusBadgeRequestDto,
    CensusClient,
    CensusBadgeDto,
    CensusGroupDto,
    PatientIntakeFacilityTypeUpdateDto,
    CensusDto,
} from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';
import { scheduleBadgesAdapter, ScheduleState } from './models';
import { handleError } from '../../../../shared/common/HandleErrors';
import { scheduleSlice } from './slice';
import { fetchFacilities } from '../admin/slice.facilities';
import { censusSlice, fetchVisitDues } from '../census/slice';
import { Role } from '../../../../shared/common/auth/RoleAuth';
import { isInRole } from '../../../../shared/common/helpers';
import { updateFacilityType } from '../census/slice.patient-intakes';
import { fetchSpecialties } from '../census/slice.specialties';

export const reducers = {
    setSchedules: (state: ScheduleState, action: PayloadAction<ProviderSchedulesDto[]>) => {
        state.schedules = action.payload;
        state.errorMessage = null;
    },

    setCurrentProvider: (state: ScheduleState, action: PayloadAction<string>) => {
        state.currentProviderId = action.payload;
    },

    setScheduleStats: (state: ScheduleState, action: PayloadAction<ScheduledPatientIntakeStatsDto>) => {
        state.stats = action.payload;
    },

    setBadgeData: (state: ScheduleState, action: PayloadAction<CensusBadgeDto[]>) => {
        scheduleBadgesAdapter.setMany(state.badges, action.payload);
    },

    removeSchedule: (state: ScheduleState, action: PayloadAction<VisitDto>) => {
        const { patientIntakeId, providerId } = action.payload;

        if (state.schedules != null && state.schedules.length > 0) {
            const newSchedules = [...state.schedules].map((schedule) => {
                const newFacilities = [...schedule.facilities].map((facility) => {
                    const newFacilitySchedules = [...facility.schedules];
                    const index = newFacilitySchedules.findIndex((x) => x.patientIntakeId === patientIntakeId && x.assignedUserId === providerId);

                    if (index !== -1) {
                        newFacilitySchedules.splice(index, 1);
                    }

                    return { ...facility, schedules: newFacilitySchedules };
                });

                return { ...schedule, facilities: newFacilities } as ProviderSchedulesDto;
            });

            state.schedules = newSchedules;
        }
    },
};

export function fetchScheduleStats(providerId: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;
        const client = new SchedulesClient(null, Axios);
        const roles = [Role.POST_ACUTE_ADMIN, Role.POST_ACUTE_PHYSICIAN, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER];

        if (!isInRole(permissions, roles)) {
            return;
        }

        try {
            const response = await client.getScheduledPatientIntakeStats(providerId);

            if (response.result.succeeded) {
                dispatch(scheduleSlice.actions.setScheduleStats(response.result.entity));
            } else {
                handleError(response, () => dispatch(scheduleSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(scheduleSlice.actions.setError(error.toString())));
        }
    };
}

export function getScheduleForProvider(providerId: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;
        const roles = [Role.POST_ACUTE_ADMIN, Role.POST_ACUTE_PHYSICIAN, Role.POST_ACUTE_ADVANCED_PRACTICE_PRACTITIONER];
        const client = new SchedulesClient(null, Axios);

        if (isInRole(permissions, roles)) {
            try {
                const { facilities } = getState().admin;
                const response = await client.getCurrent();

                if (facilities == null || facilities.length === 0) {
                    await dispatch(fetchFacilities());
                }

                if (response.result.succeeded) {
                    dispatch(scheduleSlice.actions.setCurrentProvider(providerId)); // Used for the SignalR store to sync data
                    dispatch(scheduleSlice.actions.setSchedules(response.result.entity));

                    response.result.entity.forEach((providerSchedule) => {
                        providerSchedule.facilities.forEach((facility) => {
                            const patientIntakeIds = facility.schedules.map((schedule) => schedule.patientIntakeId);

                            const badgeDto = {
                                facilityId: facility.facilityId,
                                patientIntakeIds,
                            } as CensusBadgeRequestDto;

                            const group = {
                                admittedToId: facility.facilityId,
                                patientIntakeIds: patientIntakeIds,
                            } as CensusGroupDto;

                            dispatch(fetchBadgeData(badgeDto));
                            dispatch(fetchVisitDues(group));
                            dispatch(fetchSpecialties(group.patientIntakeIds));
                        });
                    });
                } else {
                    handleError(response, () => dispatch(scheduleSlice.actions.setError(null)));
                }
            } catch (error) {
                handleError(error, () => dispatch(scheduleSlice.actions.setError(error.toString())));
            }
        }
    };
}

export function fetchBadgeData(badgeDto: CensusBadgeRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getBadgeData(badgeDto);

            if (response.result.succeeded) {
                dispatch(scheduleSlice.actions.setBadgeData(response.result.entity));
            } else {
                handleError(response, () => dispatch(scheduleSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(scheduleSlice.actions.setError(error.toString())));
        }
    };
}

export function updateVisitFacilityType(visit: VisitFacilityTypeUpdateDto, patientIntakeId?: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new SchedulesClient(null, Axios);

        try {
            const response = await client.updateFacilityType(visit);

            if (response.result.succeeded) {
                if (patientIntakeId != null) {
                    const patchedValues = {
                        patientIntakeId: patientIntakeId,
                        visitIds: visit ? [visit.id] : [],
                        facilityType: visit.facilityType,
                    } as PatientIntakeFacilityTypeUpdateDto;

                    await dispatch(updateFacilityType(patchedValues));
                }

                dispatch(censusSlice.actions.setVisit(response.result.entity));

                // Keep current census in sync as this is used to populate the signed note confirmation codes
                const { currentCensus } = getState().census;
                if (currentCensus != null) {
                    const newCurrentCensus = { ...currentCensus, facilityType: visit.facilityType } as CensusDto;

                    dispatch(censusSlice.actions.setCurrentCensus(newCurrentCensus));
                }
            } else {
                handleError(response, () => dispatch(scheduleSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(scheduleSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function postAdHocVisit(data: VisitDto): AppThunk<Promise<VisitDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentPatientIntake, currentNote } = getState().census;
        const { currentUnsignedNote } = getState().unsignedNotes;
        const client = new SchedulesClient(null, Axios);

        try {
            // We must patch the id in this scenario to ensure we target the correct visit
            // This ensures that if they're changing the note type the correct visit gets used
            if (currentNote?.visitId != null) {
                data.id = currentNote.visitId;
            }

            // We must patch the id in this scenario to ensure we target the correct visit
            // This ensures that if they're changing the note type from the unsigned note form
            // to a discharge, we will target the proper visit
            if (currentUnsignedNote?.visit != null) {
                data.id = currentUnsignedNote.visit.id;
            }

            // Facility Type should never be null
            if (currentPatientIntake != null && data.facilityType == null) {
                data.facilityType = currentPatientIntake.facilityType;
            }

            const response = await client.visit(data);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setVisit(response.result.entity));

                // Ensure visit gets removed from the schedule table if necessary
                dispatch(scheduleSlice.actions.removeSchedule(data));

                return response.result.entity;
            } else {
                handleError(response, () => dispatch(scheduleSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(scheduleSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export const selectSchedules = (state: RootState) => state.schedule.schedules;
export const selectScheduleStats = (state: RootState) => state.schedule.stats;

export const scheduleBadgeSelectors = scheduleBadgesAdapter.getSelectors();
